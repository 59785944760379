import { Component } from '@angular/core';

@Component({
    selector: 'app-health-check',
    standalone: true,
    template: `<p>health-check works!</p>
    `
})
export class HealthCheckComponent {

}
